.footer {
  font-size: small;
  background: #f5f5f5;
  padding: 5px;
  text-align: center;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}
