/* google font */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&family=VT323&display=swap");

/* layout */
:root {
  --primary: #1aac83;
  --error: #e7195a;
}
body {
  background: #f1f1f1;
  margin: 0;
  font-family: "Poppins";
}
header {
  background: #fff;
}
header .container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
header a {
  color: #333;
  text-decoration: none;
}
.pages {
  max-width: 1400px;
  padding: 20px;
  margin: 0 auto;
}

/* homepage */
.home {
  display: grid;
  /*grid-template-columns: 1fr; */
  gap: 100px;
}
.workout-details {
  background: #fff;
  border-radius: 4px;
  margin: 20px auto;
  padding: 20px;
  position: relative;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.05);
}
.workout-details h4 {
  margin: 0 0 10px 0;
  font-size: 1.2em;
  color: var(--primary);
}
.workout-details p {
  margin: 0;
  font-size: 0.9em;
  color: #555;
}
.workout-details span {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  background: #f1f1f1;
  padding: 6px;
  border-radius: 50%;
  color: #333;
}

.iframe-details {
  max-width: 500px;
  margin: 40px auto;
  padding: 20px;
  background: #fff;
  border-radius: 4px;
}
/* new workout form */
label {
  margin: 10px 10px;
  font-size: 0.9em;
  color: #555;
}

input {
  padding: 10px;
  font-family: "Poppins";

  margin: 10px 10px 10px 10px;
  width: 100%;
  border: 1px solid #ddd;

  box-sizing: border-box;
}

form .button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
form button {
  background: var(--primary);
  border: 0;
  color: #fff;
  padding: 10px;
  font-family: "Poppins";
  border-radius: 4px;
  cursor: pointer;
}

div.error {
  padding: 10px;
  background: #ffefef;
  border: 1px solid var(--error);
  color: var(--error);
  border-radius: 4px;
  margin: 20px 0;
}
input.error {
  border: 1px solid var(--error);
}

/* navbar */
nav {
  display: flex;
  align-items: center;
}
nav a,
nav button {
  margin-left: 10px;
}
nav button {
  background: #fff;
  color: var(--primary);
  border: 2px solid var(--primary);
  padding: 6px 10px;
  border-radius: 4px;
  font-family: "Poppins";
  cursor: pointer;
  font-size: 1em;
}

/* auth forms */
form.signup,
form.login,
form.voting {
  max-width: 400px;
  margin: 40px auto;
  padding: 20px;
  background: #fff;
  border-radius: 4px;
}

/* vote form */
.vote {
  padding: 10px;
  font-family: "Poppins";

  margin: 10px 10px 10px 10px;
}

.voting h4 {
  margin: 10px 0 10px 25px;
  font-size: 1.2em;
  color: var(--primary);
}

/* footer 
footer {
  background: #fff;
  padding: 20px;
  text-align: center;
  margin-top: 40px;
} */

.radio-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.radio-input {
  display: none;
}

.radio-label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.radio-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 10px;
  border: 2px solid transparent;
  transition: border-color 0.2s ease-in-out;
}

.radio-name {
  font-size: 14px;
}

.radio-input:checked + .radio-label .radio-image {
  border: 2px solid var(--primary);
}
